import React from 'react'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

import { sleep } from 'utilities/promises'

const bugsnagClient = Bugsnag.start({
  apiKey:
    typeof window !== 'undefined'
      ? process.env.BUGSNAG_BROWSER_API_KEY
      : process.env.BUGSNAG_SSR_API_KEY,
  releaseStage: process.env.APP_ENV,
  enabledReleaseStages: ['growth', 'staging', 'production'],
  plugins: [new BugsnagPluginReact(React)],
})

export const bugsnagNotifyAsync = async error => {
  await Promise.race([
    sleep(2000), // timeout
    new Promise(resolve => {
      Bugsnag.notify(error, () => {
        console.log('Bugsnag notified!')
        resolve(true)
      })
    }),
  ])
}

export default bugsnagClient
