export const DETAILS = 'DETAILS'
export const SMS = 'SMS'
export const NUTRITION = 'nutrition'
export const NUTRITION_SETTINGS = 'nutrition-settings'
export const SCHEDULE = 'schedule'
export const MEALS = 'meals'
export const SHORT_WORKOUTS = 'short-workouts'
export const SUGGESTED_MEALS = 'suggested-meals'
export const WORKOUTS = 'workouts'
export const HABITS = 'habits'
export const TASKS = 'client-tasks'
export const FEED = 'feed'
export const INFO = 'info'
export const RESOURCES_FOR_CLIENTS = 'resources-for-clients'
export const OPEN_TO_SECTION_PARAM = 'openToSection'
export const OPEN_TO_SECTION = {
  INFO: 'info',
  INJURIES: 'injuries',
  COACH_NOTES: 'coach-notes',
  FITNESS_GOALS: 'fitness-goals',
  AUTO_SCHEDULER: 'auto-scheduler',
  AUTO_SCHEDULER_v2: 'schedule-workouts',
  KPIS: 'kpis',
  EXCLUSIONS: 'exclusions',
  DUE_DATES: 'due-dates',
  LINKS: 'links',
  SETTINGS: 'settings',
  SUGGESTED_MEALS: 'suggested-meals',
  MEAL_PLAN: 'meal-plan',
  SHORT_WORKOUTS: 'short-workouts',
  TASK_COMPLETION_PROGRESS: 'task-completion-progress',
  UPLOAD_WELCOME_VIDEO: 'upload-welcome-video',
}
export const OPEN_TO_TAB = {
  NUTRITION,
  NUTRITION_SETTINGS,
  SCHEDULE,
  MEALS,
  SHORT_WORKOUTS,
  SUGGESTED_MEALS,
  HABITS,
}
export const INFO_PARAM = 'info'

export const DESKTOP_TABS = [WORKOUTS, NUTRITION]
export const MOBILE_TABS = [FEED, WORKOUTS, NUTRITION]
